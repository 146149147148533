import React, { useEffect } from "react";
// import internal from "stream";

const StepUpForm = ({
  stepUpToken,
  transactionId,
  cardToken,
  internelTYransactionId,
  stepUpUrl,
  cyberSourceSessionId,
}) => {
  useEffect(() => {
    const form = document.querySelector("#step-up-form");
    if (form) form.submit();
  }, []);

  // Check iframe content every second (1000ms)
  console.log(cyberSourceSessionId, "skdiofhui");
  const accessToken = localStorage.getItem("accessToken");
  const arrayToken = accessToken.split(".");
  const tokenPayload = JSON.parse(atob(arrayToken[1]));
  const searchParams = new URLSearchParams(window.location.search);
  const clientId = searchParams.get("clientId");
  localStorage.setItem("userId", tokenPayload.sub);
  localStorage.setItem("clientId", clientId);
  localStorage.setItem("transactionId", transactionId);
  localStorage.setItem("cardToken", cardToken);
  localStorage.setItem("internelTYransactionId", internelTYransactionId);
  let width = window.innerWidth;
  let height = window.innerHeight;

  const obj = {
    clientId: clientId,
    internalTid: transactionId,
    userId: tokenPayload.sub,
    tokenId: cardToken,
    fingerPrintSessionId: `${cyberSourceSessionId}`,
  };
  return (
    <div>
      <iframe
        name="step-up-iframe"
        width={width}
        height={height}
        title="Step Up Iframe"
        style={{
          overflow: "none",
        }}
      ></iframe>
      <form
        id="step-up-form"
        target="step-up-iframe"
        method="post"
        action={stepUpUrl}
      >
        <input type="hidden" name="JWT" value={stepUpToken} />
        <input type="hidden" name="MD" value={JSON.stringify(obj)} />
      </form>
    </div>
  );
};

export default StepUpForm;
