import react, { createContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { InitializaPayment } from "./component/initializaPayment";
// import Loader from "./component/loader";
import NewLoader from "./component/Checkout/NewLoader";
import Checkout from "./component/Checkout/Checkout";
import { razorPayRedirectToContent } from "./component/utills/mobilehandlers";
// import Thankyou from "./component/Thankyou/Thankyou";
import ThankyouNew from "./component/Thankyou/ThankyouNew";
import { useEffect, useState } from "react";
import API from "./Api/api";
import { antdMessageError } from "./component/Checkout/utills";
export const MyContext = createContext({
  brandLogoUrl: "",
  tnc: "",
  privPolicy: "",
  buttonBackground: "",
  buttonFontColor: "",
});

function BackButtonHandler() {
  useEffect(() => {
    const redirectToSLP = async () => {
      try {
        const searchParams = new URLSearchParams(window.location.search);
        const redirecturl = new URL(searchParams.get("siteUrl") || "");
        redirecturl.searchParams.append("backClick", 'true');
        window.location.href = redirecturl.href;
      } catch (error) {
        console.error("Error during redirect:", error);
      }
    };

    const handlePopState = () => {
      console.log("Back button detected");
      redirectToSLP();
    };

    const pushInitialState = () => {
      // Push the current state into history to handle back correctly
      window.history.pushState(null, "", window.location.href);
    };

    pushInitialState();
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return null;
}

function App() {
  const [loginSetting, setLoginSetting] = useState<any>({});
  const [checkoutSetting, setCheckoutSetting] = useState<any>({});
  useEffect(() => {
    const handlePageShow = (event: any) => {
      if (event.persisted) {
        // Page is restored from bfcache
        // Perform any action needed like re-fetching data or forcing a re-render
        console.log("Page was restored from bfcache");
        // Example: force re-render
        window.location.reload();
      }
    };

    window.addEventListener("pageshow", handlePageShow);

    return () => {
      window.removeEventListener("pageshow", handlePageShow);
    };
  }, []);

  const [loaded, setLoaded] = useState(false);

  function includeRazorpay() {
    const rzpScript = document.createElement("script");
    rzpScript.src = "https://checkout.razorpay.com/v1/checkout.js";
    rzpScript.crossOrigin = "anonymous";
    rzpScript.type = "application/javascript";
    rzpScript.async = true;
    rzpScript.onload = () => {
      setLoaded(true);
    };
    document.body.appendChild(rzpScript);

    return rzpScript;
  }

  useEffect(() => {
    includeRazorpay();
  });
  useEffect(() => {
    const mobileView =
      new URLSearchParams(window.location.search).get(
        "mobileContentPurchase"
      ) || undefined;
    if (mobileView) {
      razorPayRedirectToContent(
        "SUCCESS",
        localStorage.getItem("userId") || ""
      );
    }
  }, []);

  return (
  
    // >
      <Router>
        <BackButtonHandler />
        <Routes>
          <Route path="/" element={<InitializaPayment />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/thankyou" element={<ThankyouNew />} />
          <Route path="/statuscheck" element={<NewLoader/>}/>
        </Routes>
      </Router>
    // </MyContext.Provider>
  );
}

export default App;
