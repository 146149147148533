// Loader.tsx
import React, { useEffect } from "react";
import paymentStyle from "../payment.module.css";

const NewLoader = ({ message }: { message?: string }) => {
  const queryData = new URLSearchParams(window.location.search);
  const paymentStatus = queryData.get("paymentStatus") || "";
  console.log(paymentStatus, "paymentStatus");

  useEffect(() => {
    if (paymentStatus === "false") {
      window.parent.postMessage({ source: "Transaction-Failed" }, "*");
    } else {
      window.parent.postMessage({ source: "Transaction-Complete" }, "*");
    }
  }, [paymentStatus]);

  return (
    <div className={paymentStyle["loader-container"]}>
      <div id={paymentStyle["loader"]}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p className={paymentStyle["loader-text"]}>
        {message ? message : ""}
        {/* Initializing your payment process. Please don't refresh the page. */}
      </p>
    </div>
  );
};

export default NewLoader;
