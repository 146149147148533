import axios from "axios";
import API from "../../Api/api";
import { message } from "antd";
import { makeRzpPayment } from "../makeRzpPayment";
interface DetailsType {
  [key: string]: {
    isRequired: boolean;
    mandatory: boolean;
  };
}
export interface CheckoutSettingsType {
  contactInfo: DetailsType;
  giftingDetails: DetailsType;
  deliveryAddress: {
    default: boolean;
    customization: DetailsType;
  };
  billingAddress: boolean;
  taxId: {
    taxIdInput: boolean;
    corporateMandatory: boolean;
    retailMandatory: boolean;
  };
  customizeCTA: CtaType;
}
function findCardType(cardTypeNumber: string) {
  if (cardTypeNumber === "001") {
    return "VS";
  } else if (cardTypeNumber === "002") {
    return "MS";
  } else if (cardTypeNumber === "003") {
    return "AMEX";
  } else if (cardTypeNumber === "004") {
    return "DS";
  } else if (cardTypeNumber === "005") {
    return "DINER";
  } else if (cardTypeNumber === "007") {
    return "JCB";
  }
}
export interface CtaType {
  backgroundColor: string;
  text: string;
  color: string;
  fontWeight: string;
  bold: boolean;
  fontStyle: string;
  italic: boolean;
}
export const checkoutSettingsDefaultData = {
  contactInfo: {
    nameInput: {
      isRequired: false,
      mandatory: false,
    },
    email: {
      isRequired: false,
      mandatory: false,
    },
    mobile: {
      isRequired: false,
      mandatory: false,
    },
    DOB: {
      isRequired: false,
      mandatory: false,
    },
    gender: {
      isRequired: false,
      mandatory: false,
    },
  },
  giftingDetails: {
    recipientName: {
      isRequired: false,
      mandatory: false,
    },
    recipientEmailPhone: {
      isRequired: false,
      mandatory: false,
    },
    message: {
      isRequired: false,
      mandatory: false,
    },
  },
  deliveryAddress: {
    default: false,
    customization: {
      physicalSubscription: {
        isRequired: false,
        mandatory: false,
      },
      digitalSubscription: {
        isRequired: false,
        mandatory: false,
      },
      adsFreeSubscription: {
        isRequired: false,
        mandatory: false,
      },
    },
  },
  billingAddress: true,
  taxId: {
    taxIdInput: false,
    corporateMandatory: false,
    retailMandatory: false,
  },
  customizeCTA: {
    backgroundColor: "",
    text: "",
    color: "",
    fontWeight: "",
    bold: false,
    fontStyle: "",
    italic: false,
  },
} as CheckoutSettingsType;

export const URLshortner = async (url: string) => {
  const data = await axios({
    url: `${process.env.REACT_APP_API_URL_V2}/url-shortner`,
    method: "POST",
    data: {
      url: url,
    },
  });
  return data.data;
};
export const callrzpSubscription = async (orderObj: any) => {
  const urlParams = new URLSearchParams(window.location.search);

  urlParams.delete("siteUrl");

  const newUrlSrp =
    window.location.origin +
    window.location.pathname +
    "?" +
    urlParams.toString();

  function rzrPayCrossBtn() {
    orderObj.setLoading(false);
    window.location.reload();
  }
  // setLoading
  function setLoading() {
    console.log("kjdshkl");
  }
  const searchParams = new URLSearchParams(window.location.search);

  if (!orderObj.renew) {
    try {
      const cardExpiryMonth = orderObj.month;
      const cardExpiryYear = orderObj.year;
      const cardNumber = orderObj.cardNumber;
      const nameOnCard = orderObj.cardHolderName;
      const giftingDetails = orderObj.isGift
      ? {
          recieverName: orderObj.recipientName,
          recieverEmailPhone: orderObj.recipientEmail,
          recieverMessage: orderObj.recieverMessage,
          sender: orderObj.sender,
        }
      : "";
      if (orderObj.routing.gateway === "CYBERSOURCE") {
        const cyberSourceOrderResponse = await API.payment.cyberSourceOrder({
          paywallId: sanitizeValue(orderObj.paywallId),
          paywallType: sanitizeValue(orderObj.paywallType),
          manuallyRenewed: false,
          consumeFreeTrial: false,
          clientContentId: orderObj.clientContentId,
          successFrontendUrl: "https://www.google.com",
          failureFrontendUrl: "https://www.google.com",
          clientId: orderObj.clientId,
          subscriptionId: orderObj.SubscriptionId,
          anonId: orderObj.anonId,
          tierId: orderObj.tierId,
          type: orderObj.type,
          userDetails: {
            ...orderObj.userDetails,
            ...(orderObj.isGift && { isGift: orderObj.isGift }),
            ...(giftingDetails && { giftingDetails }),
          },
          userId: orderObj.userId,
          cyberSourceReferenceId: orderObj.cyberSourceSessionId,
          cardExpiryMonth,
          cardExpiryYear,
          cardNumber,
          nameOnCard,
          tokenId: orderObj.cardToken,
          cardTypeNumber: orderObj.cardType,
          cardType: findCardType(orderObj.cardType.toString()),
          amount: searchParams.get("amount")
            ? +orderObj.subsDetailsData.convertedPrice + orderObj.tax
            : "",
          currency: searchParams.get("currency")
            ? orderObj.subsDetailsData.currency
            : "",

          numberOfLicense: orderObj.numberOfLicense,
          couponCode: orderObj?.couponCode,
          clientReferenceId: searchParams.get("clientReferenceId") || "",
        
        });

        const transactionId = cyberSourceOrderResponse.data.data.transactionId;
        if (cyberSourceOrderResponse.data.data.status === "AUTHORIZED") {
          const secondaryDetails =
            new URLSearchParams(window.location.search).get(
              "secondaryDetails"
            ) || undefined;
          window.location.href = `${
            window.location.origin
          }/thankyou?transactionId=${transactionId}&clientContentId=${searchParams.get(
            "clientContentId"
          )}&clientId=${orderObj.clientId}&secondStep=${
            orderObj.secondStep
          }&thirdstep=${orderObj.thirdstep}&popUpId=${
            orderObj.popUpId
          }&secondaryDetails=${secondaryDetails}`;
        } else if (cyberSourceOrderResponse.data.data.status === "DECLINED") {
          return;
        } else {
          // orderObj.setCyberSourceSessionId('')
          orderObj.setCyberSourcedata({
            accessToken: "",
            deviceDataCollectionUrl: "",
            refrenceId: "",
          });
          orderObj.setStepUpToken(
            cyberSourceOrderResponse.data.data.consumerAuthenticationInformation
              .accessToken
          );
          orderObj.setTransactionId(
            cyberSourceOrderResponse.data.data.transactionId
          );
          orderObj.setInternelTransactionId(
            cyberSourceOrderResponse.data.data.consumerAuthenticationInformation
              .authenticationTransactionId
          );
          orderObj.setStepUrl(
            cyberSourceOrderResponse.data.data.consumerAuthenticationInformation
              .stepUpUrl
          );
          orderObj.setShowStepUp(true);
          orderObj.setLoading(false);
        }
        return;
      }
    
      const pgIntegrationResponse = await API.payment.pgIntegration({
        paywallId: sanitizeValue(orderObj.paywallId),
        paywallType: sanitizeValue(orderObj.paywallType),
        clientContentId: orderObj.contentId,
        clientId: orderObj.clientId,
        subscriptionId: orderObj.SubscriptionId,
        anonId: orderObj.anonId,
        tierId: orderObj.tierId,
        type: orderObj.type,
        userDetails: {
          ...orderObj.userDetails,
          ...(orderObj.isGift && { isGift: orderObj.isGift }),
          ...(giftingDetails && { giftingDetails }),
        },
        userId: orderObj.userId,
        numberOfLicense: orderObj.numberOfLicense,
        amount: searchParams.get("amount")
          ? +orderObj.subsDetailsData.convertedPrice + orderObj.tax
          : "",
        currency: searchParams.get("currency")
          ? orderObj.subsDetailsData.currency
          : "",
        couponCode: orderObj.couponCode,
        clientReferenceId: searchParams.get("clientReferenceId") || "",
      });
      if (!API.validators.checkSuccessCode(pgIntegrationResponse)) {
        orderObj.setLoading(false);
        return;
      } else {
        const transactionid = pgIntegrationResponse?.data?.data?.transactionId;
        const pgData = pgIntegrationResponse.data.data;
        if (!pgData.rzpOrderId) return;
        // const failureUrl =
        const clientName = "";
        const secondaryDetails =
          new URLSearchParams(window.location.search).get("secondaryDetails") ||
          undefined;

        const redirectUrl = `${
          window.location.origin
        }/thankyou?transactionId=${transactionid}&clientId=${
          orderObj.clientId
        }&clientContentId=${searchParams.get("clientContentId")}&secondStep=${
          orderObj.secondStep
        }&thirdstep=${orderObj.thirdstep}&popUpId=${
          orderObj.popUpId
        }&secondaryDetails=${secondaryDetails}&failureUrl=${window.btoa(
          newUrlSrp
        )}`;
        const localPhone = orderObj.userDetails.phone
          ? orderObj.userDetails.phone
          : orderObj.userDetails.secondaryPhone;
        const localCountryCode = orderObj.userDetails.countryCode || "";
        const localemail = orderObj.userDetails.email
          ? orderObj.userDetails.email
          : orderObj.userDetails.secondaryEmail;
        makeRzpPayment(
          pgData.rzpOrderId,
          pgData.color,
          pgData.logoUrl,
          localCountryCode,
          localPhone,
          localemail,
          rzrPayCrossBtn,
          setLoading,
          pgData.publishableKey,
          redirectUrl,
          transactionid,
          clientName,
          orderObj.userDetails,
          pgData.currency,
          pgData.amount
        );
      }
    } catch (error: any) {
      orderObj.setLoading(false);
      if (error?.response) {
        if (error.response.status === 400 || error.response.status === 500) {
          error.response.data.message &&
            antdMessageError(error.response.data.message);
        } else {
          antdMessageError(
            "An unexpected error occurred. Please try again later."
          );
        }
      } else {
        antdMessageError(
          "An unexpected error occurred. Please try again later."
        );
      }
    }
  } else {
    if (orderObj.routing.gateway === "RAZORPAY") {
      try {
        const giftingDetails = orderObj.isGift
          ? {
              recieverName: orderObj.recipientName,
              recieverEmailPhone: orderObj.recipientEmail,
              recieverMessage: orderObj.recieverMessage,
              sender: orderObj.sender,
            }
          : "";
        const postreneweldata = await API.user.postRenewal({
          clientContentId: orderObj.contentId,
          paywallId: sanitizeValue(orderObj.paywallId),
          paywallType: sanitizeValue(orderObj.paywallType),
          clientId: orderObj.clientId,
          subscriptionId: orderObj.SubscriptionId,
          anonId: orderObj.anonId,
          tierId: orderObj.tierId,
          type: orderObj.type,
          amount: searchParams.get("amount")
            ? +orderObj.subsDetailsData.convertedPrice + orderObj.tax
            : "",

          currency: searchParams.get("currency")
            ? orderObj.subsDetailsData.currency
            : "",

          userDetails: {
            ...orderObj.userDetails,
            ...(orderObj.isGift && { isGift: orderObj.isGift }),
            ...(giftingDetails && { giftingDetails }),
          },
          userId: orderObj.userId,
          couponCode: orderObj.couponCode,
          clientReferenceId: searchParams.get("clientReferenceId") || "",
          numberOfLicense: orderObj.numberOfLicense,
        });
        const rzpSubscriptionId = postreneweldata.data.data.rzpSubscriptionId;
        const rzpSubscriptonNotes = postreneweldata.data.data.notes;
        const primaryColor = postreneweldata.data.data.color;
        const brandLogoUrl = postreneweldata.data.data.logoUrl;
        const transactionId = postreneweldata.data.data.renewalId;
        console.log(rzpSubscriptonNotes, "kjhfdioj");

        const secondaryDetails =
          new URLSearchParams(window.location.search).get("secondaryDetails") ||
          undefined;
        const redirectUrl = `${
          window.location.origin
        }/thankyou?renewalId=${transactionId}&clientId=${
          orderObj.clientId
        }&secondStep=${orderObj.secondStep}&clientContentId=${searchParams.get(
          "clientContentId"
        )}&thirdstep=${
          orderObj.thirdstep
        }&secondaryDetails=${secondaryDetails}&failureUrl=${window.btoa(
          newUrlSrp
        )}`;
        const localRedirectUrl = window.btoa(redirectUrl);
        console.log(orderObj.userDetails, "orderObj.userDetails");

        const options = {
          key: postreneweldata.data.data.publishableKey,
          subscription_id: rzpSubscriptionId,
          currency: postreneweldata.data.data.currency,
          amount: postreneweldata.data.data.amount,
          notes: {
            rzpSubscriptionId,
            ...rzpSubscriptonNotes,
          },
          name: "",
          image:
            brandLogoUrl ||
            "https://conscent-static-assets.s3.ap-south-1.amazonaws.com/assets/conscent+logo.png",
          prefill: {
            contact: `${orderObj.userDetails.countryCode || ""}${
              orderObj.userDetails.phone || orderObj.userDetails.secondaryPhone
            }`,
            email:
              orderObj.userDetails.email || orderObj.userDetails.secondaryEmail,
          },
          redirect: true,
          modal: {
            confirm_close: false,
            ondismiss: function () {
              orderObj.setLoading(false);
              window.location.reload();
            },
          },
          callback_url: `${process.env.REACT_APP_API_URL}/payment/gateway/rzpRedirect/?redirectUrl=${localRedirectUrl}`,
          theme: {
            color: primaryColor || "#1a92b4",
          },
        };
        console.log(options, "options");

        // @ts-ignore
        const rzp = new window.Razorpay(options);
        rzp.open();
        return;
      } catch (error) {
        orderObj.setLoading(false);
        antdMessageError(
          "An unexpected error occurred. Please try again later."
        );
      }
    } else {
      const cardExpiryMonth = orderObj.month;
      const cardExpiryYear = orderObj.year;
      const cardNumber = orderObj.cardNumber;
      const nameOnCard = orderObj.cardHolderName;
      const giftingDetails = orderObj.isGift
      ? {
          recieverName: orderObj.recipientName,
          recieverEmailPhone: orderObj.recipientEmail,
          recieverMessage: orderObj.recieverMessage,
          sender: orderObj.sender,
        }
      : "";
      const cyberSourceOrderResponse =
        await API.payment.cyberSourceOrderrenewal({
          paywallId: sanitizeValue(orderObj.paywallId),
          paywallType: sanitizeValue(orderObj.paywallType),
          manuallyRenewed: false,
          consumeFreeTrial: false,
          clientContentId: "Client-Story-Id-1",
          successFrontendUrl: "https://www.google.com",
          failureFrontendUrl: "https://www.google.com",
          clientId: orderObj.clientId,
          subscriptionId: orderObj.SubscriptionId,
          anonId: orderObj.anonId,
          tierId: orderObj.tierId,
          type: orderObj.type,
          userDetails: {
            ...orderObj.userDetails,
            ...(orderObj.isGift && { isGift: orderObj.isGift }),
            ...(giftingDetails && { giftingDetails }),
          },
          userId: orderObj.userId,
          cyberSourceReferenceId: orderObj.cyberSourceSessionId,
          cardExpiryMonth,
          cardExpiryYear,
          cardTypeNumber: orderObj.cardType,
          cardType: findCardType(orderObj.cardType.toString()),
          cardNumber,
          amount: searchParams.get("amount")
            ? +orderObj.subsDetailsData.convertedPrice + orderObj.tax
            : "",
          currency: searchParams.get("currency")
            ? orderObj.subsDetailsData.currency
            : "",
          nameOnCard,
          tokenId: orderObj.cardToken,
          numberOfLicense: orderObj.numberOfLicense,
          couponCode: orderObj.couponCode,
          clientReferenceId: searchParams.get("clientReferenceId") || "",
         
        });
      const transactionId = cyberSourceOrderResponse.data.data.transactionId;
      if (cyberSourceOrderResponse.data.data.status === "AUTHORIZED") {
        const secondaryDetails =
          new URLSearchParams(window.location.search).get("secondaryDetails") ||
          undefined;
        window.location.href = `${
          window.location.origin
        }/thankyou?transactionId=${transactionId}&clientId=${
          orderObj.clientId
        }&secondStep=${orderObj.secondStep}&thirdstep=${
          orderObj.thirdstep
        }&popUpId=${
          orderObj.popUpId
        }&secondaryDetails=${secondaryDetails}&clientContentId=${searchParams.get(
          "clientContentId"
        )}`;
      } else if (cyberSourceOrderResponse.data.data.status === "DECLINED") {
        return;
      } else {
        cyberSourceOrderResponse?.data?.data?.error &&
          antdMessageError(cyberSourceOrderResponse.data.data.error);
        orderObj.setCyberSourcedata({
          accessToken: "",
          deviceDataCollectionUrl: "",
          refrenceId: "",
        });
        orderObj.setStepUpToken(
          cyberSourceOrderResponse.data.data.consumerAuthenticationInformation
            .accessToken
        );
        orderObj.setTransactionId(
          cyberSourceOrderResponse.data.data.transactionId
        );
        orderObj.setInternelTransactionId(
          cyberSourceOrderResponse.data.data.consumerAuthenticationInformation
            .authenticationTransactionId
        );
        orderObj.setStepUrl(
          cyberSourceOrderResponse.data.data.consumerAuthenticationInformation
            .stepUpUrl
        );
        orderObj.setShowStepUp(true);
        orderObj.setLoading(false);
      }
      return;
    }
  }
};

export function sanitizeValue(value: any) {
  return value === null ||
    value === undefined ||
    value === "null" ||
    value === "undefined"
    ? ""
    : value;
}

export function removeURLParam(param: string) {
  // Get the current URL
  const url = new URL(window.location.href);

  // Remove the specified parameter
  url.searchParams.delete(param);

  // Update the URL in the browser without reloading the page
  window.history.replaceState({}, document.title, url);
}

export const getWordsFromDuration = (duration: number, unit: string) => {
  if (unit === undefined) {
    unit = "months";
  }
  if (duration <= 0) return "Invalid duration";

  let result = "";

  switch (unit) {
    case "days": {
      const years = Math.floor(duration / 365);
      const remainingDaysAfterYears = duration % 365;
      const months = Math.floor(remainingDaysAfterYears / 30);
      const days = remainingDaysAfterYears % 30;

      if (years) result += `${years} year${years > 1 ? "s" : ""}`;
      if (months)
        result += `${result ? " & " : ""}${months} month${
          months > 1 ? "s" : ""
        }`;
      if (days)
        result += `${result ? " & " : ""}${days} day${days > 1 ? "s" : ""}`;
      break;
    }

    case "months": {
      const years = Math.floor(duration / 12);
      const months = duration % 12;

      if (years) result += `${years} year${years > 1 ? "s" : ""}`;
      if (months)
        result += `${result ? " & " : ""}${months} month${
          months > 1 ? "s" : ""
        }`;
      break;
    }

    case "years": {
      result = `${duration} year${duration > 1 ? "s" : ""}`;
      break;
    }

    default:
      result = "Invalid unit";
  }

  return result;
};

export const antdMessageError = (content: string) => {
  return message.error({
    content: content,
    style: {
      marginTop: "130px",
    },
  });
};
export const antdMessageSuccess = (content: string) => {
  return message.success({
    content: content,
    duration: 2,
    style: {
      marginTop: "130px",
    },
  });
};

export const detectCardType = (number: string): string => {
  const cleanedNumber = number.replace(/\D/g, "");

  if (/^4/.test(cleanedNumber)) {
    return "001"; // VS (Visa)
  } else if (/^5[1-5]/.test(cleanedNumber)) {
    return "002"; // MS (MasterCard)
  } else if (/^3[47]/.test(cleanedNumber)) {
    return "003"; // AMEX (American Express)
  } else if (/^6(?:011|5)/.test(cleanedNumber)) {
    return "004"; // DS (Discover)
  } else if (/^35/.test(cleanedNumber)) {
    return "007"; // JCB
  } else if (/^3(?:0[0-5]|[68])/.test(cleanedNumber)) {
    return "005"; // DINER (Diners Club)
  } else {
    return "Unknown";
  }
};
