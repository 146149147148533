export const makeRzpPayment = async (
  orderId?: string,
  primaryColor?: string,
  brandLogoUrl?: string,
  countryCode?: string, 
  phoneNumber?: string,
  email?: string,
  rzrPayCrossBtn?: any,
  setLoading?: (b: boolean) => any,
  publishableKey?: string,
  redirectUrl?: string,
  transactionId?: string,
  clientName?:string,
  userDetails?:any,
  currency?: string,
  amount?: string
) => {  
  //@ts-ignore
  const localRedirectUrl = window.btoa(redirectUrl);
  // console.log(countryCode)
  
  const options = {
    key: publishableKey,
    name: clientName,
    description: '',
    image: brandLogoUrl || 'https://conscent-static-assets.s3.ap-south-1.amazonaws.com/assets/conscent+logo.png',
    order_id: orderId,
    currency: currency,
    amount:amount,
    prefill: {
      contact: `${countryCode}${phoneNumber}`,
      email: email,
    },
    notes: {
     name:userDetails.name,
     email:userDetails.email,
     phoneNumber:userDetails.phone,
    },
    redirect: true,
    callback_url: `${process.env.REACT_APP_API_URL}/payment/gateway/rzpRedirect/?redirectUrl=${localRedirectUrl}`,
    theme: {
      color: primaryColor || '#1a92b4',
    },
    modal: {
      confirm_close: false,
      ondismiss: function () { 
        rzrPayCrossBtn(transactionId);
        
      },
    },
  };

  // @ts-ignore
  const rzp = new window.Razorpay(options);
  setLoading?.(false);
  rzp.open();
};