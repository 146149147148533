import React, { useEffect, useState } from "react";
import API from "../Api/api";
import SubscriptionPurchase from "./subscription/SubscriptionPurchase";
import PassContentPurchase from "./PassContent/PassContentPurchase";

const Payment = () => {
  const [loading, setLoading] = useState(false);
  // const [paypalRoutData, setPaypalRoutData] = useState({
  //   status: '',
  //   paymentGatewayOrderPrams: {},
  // });
  // const [routing, setRouting] = useState({
  //   color: '',
  //   gateway: '',
  //   logoUrl: '',
  // });
  const subsDetails =
    new URLSearchParams(window.location.search).get("subsDetails") || undefined;
  const contentPassDetails =
    new URLSearchParams(window.location.search).get("contentPassDetails") ||
    undefined;

  // const CallRoutingData = async () => {
  //   const routingResponse = await API.payment.CallRouting({ clientId: "646324ffad2f15209a54c2b9" });
  //   if (!API.validators.checkSuccessCode(routingResponse)) return alert('Unable To Update Segment');
  //   setRouting(routingResponse?.data);
  // };

  // useEffect(() => {
  //   CallRoutingData();
  // }, []);

  // const contentDetails = {
  //   eventLocation: 'CONTENT_FLOW_ADD_MONEY_PAGE',
  //   firstTimeLogin: true,
  //   anonId: "b684789b-cf7e-44a4-8aa8-1f9bf0df2c8b",
  //   clientId: "646324ffad2f15209a54c2b9",
  //   clientContentId: "buddha-Story-Id-1",
  //   price: 999,
  //   currency: "INR",
  //   userId: "65cde9cc8c32a3055afbaee2",
  //   paywallId: "65c5c25c1784cc12b49460f0",
  //   paywallType: "REGULAR",
  //   journeyId: '',
  //   templateId: '',
  //   type: 'SUBSCRIPTION',
  //   contentPurchaseType: {
  //     adFree: false,
  //     digital: true
  //   },
  //   successFrontendUrl: "http://localhost:3000/overlay?clientId=5f92a62013332e0f667794dc&anonId=b684789b-cf7e-44a4-8aa8-1f9bf0df2c8b&paywallId=65b8b898c769ba05820f8825&userSourceData=OTHERS&clientContentId=Client-Story-Id-1&hide=000&pageUrl=http%253A%252F%252Flocalhost%253A3002%252F5f92a62013332e0f667794dc%252FClient-Story-Id-1&fullscreen=true&showLogin=true&transactionId={TRANSACTION_ID}&viewId=65cde9598c32a3055afbaee1",
  //   failureFrontendUrl: "http://localhost:3000/overlay?clientId=5f92a62013332e0f667794dc&anonId=b684789b-cf7e-44a4-8aa8-1f9bf0df2c8b&paywallId=65b8b898c769ba05820f8825&userSourceData=OTHERS&clientContentId=Client-Story-Id-1&hide=000&pageUrl=http%253A%252F%252Flocalhost%253A3002%252F5f92a62013332e0f667794dc%252FClient-Story-Id-1&fullscreen=true&showLogin=true&status=FAILURE",
  //   popupId: '',
  // } as Record<string, any>;

  // const passContentDetails = {
  //   clientId: '646324ffad2f15209a54c2b9',
  //   clientContentId: 'buddha-Story-Id-1',
  //   type: 'CONTENT',
  //   viewId: '65cf2cd33837a0133eba6679',
  //   anonId: 'b684789b-cf7e-44a4-8aa8-1f9bf0df2c8b',
  //   purchaseLocation: 'CONTENT_FLOW_ADD_MONEY_PAGE',
  //   popupId: '',
  //   paywallId: '65c5c25c1784cc12b49460f0',
  //   firstTimeLogin: true,
  //   contentDetails: contentDetails,
  //   paywallType: 'REGULAR',
  // }

  // const intializeRazorpay = async () => {
  // if(subsDetails){
  // return(
  //   <subscriptionPurchase/>
  // )

  // }
  // }

  // useEffect(() => {
  //   intializeRazorpay()
  // }, [])

  return (
    <>
      {subsDetails && <SubscriptionPurchase />}
      {contentPassDetails && <PassContentPurchase />}
    </>
    // {subsDetails && <SubscriptionPurchase/>}
  );
};

export default Payment;
