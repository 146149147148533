import React, { useEffect, useRef } from "react";
import { getDomainFromUrl } from "../../Api/utills";

// function CyberSource({accessToken,deviceDataCollectionUrl,refrenceId}:{accessToken:string,deviceDataCollectionUrl:string,refrenceId:string}) {
function CyberSource({
  cyberSourcedata,
  setCyberSourceSessionId,
}: {
  cyberSourcedata: any;
  setCyberSourceSessionId: any;
}) {
  console.log(cyberSourcedata, "sdkjhgiudsh");

  const formRef = useRef(null);

  const handleSubmit = () => {
    // Submit the form programmatically
    if (formRef.current) {
      //@ts-ignore
      formRef.current.submit();
    }
  };
  useEffect(() => {
    handleSubmit();
  }, []);

  // Set up the listener for messages from the iframe
  React.useEffect(() => {
    const messageListener = (event: any) => {
      // console.log(event.data,'dskjhsuidf');
      const data = JSON.parse(event.data);
      console.log(data.SessionId, "dskjhsuidf");

      if (event.origin === getDomainFromUrl(cyberSourcedata.deviceDataCollectionUrl)) {
        setCyberSourceSessionId(data.SessionId);
      }
    };

    window.addEventListener("message", messageListener);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);

  return (
    <div>
      {/* <button onClick={handleSubmit}>Submit Form</button> */}
      <iframe
        id="cardinal_collection_iframe"
        name="collectionIframe"
        height="10"
        width="10"
        style={{ display: "none" }}
      ></iframe>
      <form
        ref={formRef}
        id="cardinal_collection_form"
        method="POST"
        target="collectionIframe"
        action={cyberSourcedata.deviceDataCollectionUrl}
      >
        <input
          id="cardinal_collection_form_input"
          type="hidden"
          name="JWT"
          value={cyberSourcedata.accessToken}
        />
      </form>
    </div>
  );
}

export default CyberSource;
